<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>مدیریت روش‌های پرداخت</h3>
          </v-col>
          <v-col>
            <v-btn class="primary-btn" style="float: left" @click="newMethod()">
              <v-icon left>add</v-icon>
              <h6 class="mt-1">روش پرداخت جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              clearable
              rounded
              filled
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="methods"
              empty-text="بیمه‌ای برای نمایش وجود ندارد"
              empty-filtered-text="بیمه‌ای برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ methodStatusTranslator[data.item.status] }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="red-btn pa-3"
                    @click="methodStatus(data.item.value, false)"
                    v-if="data.item.status == 'active'"
                    >غیرفعال</v-btn
                  >
                  <v-btn
                    class="primary-btn pa-3"
                    @click="methodStatus(data.item.value, true)"
                    v-else
                    >فعال</v-btn
                  >
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog width="500" v-model="newMethodDialog">
      <v-card>
        <v-card-title>
          <h3>افزودن روش پرداخت</h3>
        </v-card-title>
        <v-card-text>
          <br />
          <v-text-field
            label="نام روش پرداخت"
            outlined
            dense
            v-model="paymentMethod.text"
          ></v-text-field>
          <v-text-field
            label="مقدار روش پرداخت به انگلیسی"
            outlined
            dense
            v-model="paymentMethod.value"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="busy"
            class="red-btn"
            outlined
            @click="newMethodDialog = false"
          >
            لغو
          </v-btn>
          <v-btn
            :disabled="
              busy || paymentMethod.text == '' || paymentMethod.value == ''
            "
            class="primary-btn"
            outlined
            @click="addMethod()"
          >
            افزودن
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: true,
      busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "text", label: "روش پرداخت" },
        { key: "value", label: "مقدار" },
        { key: "status", label: "وضعیت" },
        { key: "operation", label: "مدیریت وضعیت" },
      ],
      methods: [],
      newMethodDialog: false,
      paymentMethod: {},
      methodStatusTranslator: { inactive: "غیرفعال", active: "فعال" },
    };
  },

  methods: {
    newMethod() {
      // this.$router.push("/admin/newInsurance");
      this.paymentMethod = { text: "", value: "", status: "active" };
      this.newMethodDialog = true;
    },
    addMethod() {
      this.busy = true;
      this.methods.push(this.paymentMethod);
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/settings/editPaymentMethods",
          {
            paymentMethods: this.methods,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.busy = false;
          if (res.status == 200) {
            this.toast("افزودن روش پرداخت", "success");
            this.newMethodDialog = false;
            this.getPaymentMethods();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");

          this.busy = false;
        });
    },
    methodStatus(value, active) {
      this.Busy = true;
      let rawData = this.methods;
      rawData.filter((x) => x.value == value)[0].status = active
        ? "active"
        : "inactive";
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/settings/editPaymentMethods",
          { paymentMethods: rawData },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.getPaymentMethods();
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    getPaymentMethods() {
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/settings/paymentMethods",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.methods = res.data;
            this.TotalRows = this.methods.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    onFiltered(filteredMethods) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredMethods.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.getPaymentMethods();
  },
};
</script>
